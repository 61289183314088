// JsFromRoutes CacheKey 069d64298fc6113c72b38dd6d7132254
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  edit: /* #__PURE__ */ definePathHelper(
    'get',
    '/manage/subscriptions/:subscription_uuid/:token/payment_method/edit',
  ),
  show: /* #__PURE__ */ definePathHelper(
    'get',
    '/manage/subscriptions/:subscription_uuid/:token/payment_method',
  ),
  update: /* #__PURE__ */ definePathHelper(
    'patch',
    '/manage/subscriptions/:subscription_uuid/:token/payment_method',
  ),
}
