import { Mutex } from 'async-mutex'
import { writable } from 'svelte/store'
import { allowedStyles } from 'braintree-web/hosted-fields/shared/constants'

import braintree from 'braintree-web/client'
import dataCollector from 'braintree-web/data-collector'
import hostedFields from 'braintree-web/hosted-fields'
import paypalLib from 'braintree-web/paypal-checkout'
import applePayLib from 'braintree-web/apple-pay'
import googlePayment from 'braintree-web/google-payment'

import MapStore from '@/stores/map'

let pendingClient = null
const tokenizeMutex = new Mutex()

export const cardErrors = new MapStore()
export const cardHasContent = new MapStore()
export const card = writable(null)

export async function getClient(authorization) {
  // TODO: authorization is constant and not secret. Could read it from
  // credentials at compile time with webpack plugin.
  if (pendingClient) return await pendingClient

  pendingClient = braintree.create({ authorization })
  return await pendingClient
}

// Braintree expects styling passed via javascript. To avoid duplicating
// styles, we use `getComputedStyle` to copy the styles from the `input`
// element.
function getInputStyles(classes = []) {
  const element = document.createElement('input')
  const styles = {}
  element.style.display = 'none !important'
  element.style.position = 'fixed !important'
  element.style.left = '-99999px !important'
  element.style.top = '-99999px !important'
  for (const c of classes) {
    element.classList.add(c)
  }
  document.body.appendChild(element)
  const computedStyles = window.getComputedStyle(element)
  for (const style of allowedStyles) {
    if (computedStyles[style]) {
      styles[style] = computedStyles[style]
    }
  }
  document.body.removeChild(element)
  return styles
}

export async function getDeviceData(authorization) {
  const client = await getClient(authorization)
  const dataCollectorInstance = await dataCollector.create({
    client,
    kount: false,
  })
  return await dataCollectorInstance.getDeviceData()
}

export async function createHostedFields(authorization, inputs, prefill = {}) {
  const client = await getClient(authorization)
  // TODO: change expiration if not supports formatting?
  // probably use separate expirationMonth and expriationYear with select: ['01', '02', ...]
  // supportsFormatting = hostedFields.supportsInputFormatting()

  if (RAILS_ENV === 'production') prefill = {}

  const fields = await hostedFields.create({
    client,
    styles: {
      input: {
        ...getInputStyles(),
        // Braintree doesn't support external fonts for security reasons.
        // see https://github.com/braintree/braintree-web/issues/75
        'font-family': 'monospace',
      },
      'input.has-content': {
        ...getInputStyles(['has-content', 'floating']),
      },
    },
    fields: {
      number: {
        container: inputs.numberField,
        prefill: prefill.cardNumber,
      },
      expirationDate: {
        container: inputs.expirationDateField,
        prefill: prefill.expiration,
      },
      cvv: {
        container: inputs.cvvField,
        prefill: prefill.cvv,
      },
    },
  })

  fields.on('empty', (event) => {
    cardHasContent.key(event.emittedBy).set(false)
  })
  fields.on('notEmpty', (event) => {
    cardHasContent.key(event.emittedBy).set(true)
    cardErrors.key(event.emittedBy).set(null)
  })

  fields.on('blur', (event) => {
    const field = event.fields[event.emittedBy]
    if (field.isEmpty) {
      cardErrors.key(event.emittedBy).set('Required')
    } else if (field.isValid) {
      cardErrors.key(event.emittedBy).set(null)
    } else {
      cardErrors.key(event.emittedBy).set('Invalid')
    }
  })

  fields.on('validityChange', (event) => {
    const field = event.fields[event.emittedBy]
    if (!field.isValid) return
    switch (event.emittedBy) {
      case 'number':
        fields.focus('expirationDate')
        break
      case 'expirationDate':
        fields.focus('cvv')
        break
      case 'cvv':
        break
    }
  })
  fields.on('cardTypeChange', (event) => {
    if (event.cards.length === 1) {
      card.set(event.cards[0])
    } else {
      card.set(null)
    }
  })

  return fields
}

function clearErrors() {
  ;['number', 'expirationDate', 'cvv'].forEach((key) =>
    cardErrors.key(key).set(null),
  )
}

export async function tokenizeCreditCard(
  hostedFields,
  { cardholderName, postalCode },
) {
  return await tokenizeMutex.runExclusive(async () => {
    clearErrors()
    try {
      const { nonce } = await hostedFields.tokenize({
        cardholderName,
        billingAddress: { postalCode },
      })
      return nonce
    } catch (err) {
      if (RAILS_ENV !== 'production') {
        console.error('tokenize failed', err)
      }
      switch (err.code) {
        case 'HOSTED_FIELDS_FIELDS_EMPTY':
          ;['number', 'expirationDate', 'cvv'].forEach((key) =>
            cardErrors.key(key).set('Required'),
          )
          break
        case 'HOSTED_FIELDS_FIELDS_INVALID':
          for (const field of err.details.invalidFieldKeys) {
            cardErrors.key(field).set('Invalid')
          }
          break
        // case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE': shouldn't happen to us
        case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
          cardErrors.key('cvv').set('Invalid')
          break
        case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
          cardErrors.key('number').set('Invalid')
          break
        case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
          cardErrors
            .key('number')
            .set(
              'Unable to verify card number. Check your connection and try again',
            )
          break
        default:
          throw err // set sentry pick it up
      }
    }
  })
}

export async function createPaypalButton(
  tokenizationKey,
  tokenizedCallback,
  options = {},
) {
  const client = await getClient(tokenizationKey)
  const paypal = await paypalLib.create({ client })

  // load the Paypal SDK. Annoyingly this updates a global `window.paypal`
  // Documentation link
  // https://developer.paypal.com/braintree/docs/guides/paypal/checkout-with-paypal/javascript/v3/
  await paypal.loadPayPalSDK({ vault: true })

  if (!(window.paypal && window.paypal.Buttons)) {
    return null
  }

  const button = window.paypal.Buttons({
    ...options,
    fundingSource: window.paypal.FUNDING.PAYPAL,
    createBillingAgreement: function () {
      return paypal.createPayment({
        flow: 'vault', // Required in order to sell subscriptions

        enableShippingAddress: true, // return a shipping address from tokenize
      })
    },
    onApprove: function (data, actions) {
      return paypal.tokenizePayment(data, function (err, payload) {
        if (err) {
          throw err // allow sentry to pick it up
        }

        tokenizedCallback(payload)
      })
    },

    onCancel: function (data) {
      console.log('PayPal payment canceled')
    },

    onError: function (err) {
      throw err // allow sentry to pick it up
    },
  })

  return button
}

export async function createApplePay(tokenizationKey) {
  const available =
    window.ApplePaySession &&
    window.ApplePaySession.supportsVersion(3) &&
    window.ApplePaySession.canMakePayments()
  if (!available) {
    return null
  }

  const client = await getClient(tokenizationKey)
  const applePay = await applePayLib.create({ client })

  const canMakePayments =
    await window.ApplePaySession.canMakePaymentsWithActiveCard(
      applePay.merchantIdentifier,
    )
  if (!canMakePayments) {
    console.log("Apple Pay available but can't make payments with active card.")
    return null
  }

  return applePay
}

export async function createGooglePay(tokenizationKey, onClick, options = {}) {
  const paymentsClient = new window.google.payments.api.PaymentsClient({
    environment: RAILS_ENV === 'production' ? 'PRODUCTION' : 'TEST',
  })

  const client = await getClient(tokenizationKey)
  const googlePaymentInstance = await googlePayment.create({
    client,
    googlePayVersion: 2,
    googleMerchantId: RAILS_ENV === 'production' ? 'BCR2DN4TYTZPZU3A' : null,
  })

  const readyToPay = await paymentsClient.isReadyToPay({
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods:
      googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
    existingPaymentMethodRequired: true,
  })
  if (!readyToPay.result) {
    return null
  }

  const button = paymentsClient.createButton({
    buttonSizeMode: 'fill',
    buttonType: 'plain',
    ...options,
    onClick: (ev) =>
      onClick(ev, {
        paymentsClient,
        googlePaymentInstance,
      }),
  })
  return button
}
